/// <summary>
/// Author :
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
  static _API_SUCCESS_KEY = "success";
  static _API_MESSAGE_KEY = "message";
  static _API_DATA_KEY = "data";
  static _API_CODE_KEY = "code";

  static _API_CONTENT_TYPE = "Content-Type";
  static _API_ACCEPT = "Accept";
  static _API_APPLICATION_JSON = "application/json";
  static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
  static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
  static _API_POST = "POST";
  static _API_GET = "GET";
}

/// <summary>
/// Author :
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
  static _API_LOGIN = "/Account/Login";
  static _API_LOGOUT = "/Account/Logout";
  static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
  static _API_GET_USER_PAYMENT_GATEWAY_INFO = "/Account/RetrievePaymentGatewayInfo";
  static _API_SEND_RESET_PASSWORD_SMS = "/Account/SendResetPasswordSms";
  static _API_CREATE_OR_UPDATE_MEMBER = "/Account/InitiateOrReviseMember";
  static _API_UPDATE_MEMBER_USERFULLNAME = "/Account/ReviseMemberUserFullname";
  static _API_GET_USER_ANNOUNCEMENT = "/Account/RetrieveUserAnnouncement";
  static _API_GET_COMPANY_CONTACT_US = "/Account/RetrieveCompanyContactUs";
  static _API_VERIFY_RESET_PASSWORD_SMS = "/Account/ValidateResetPasswordSms";

  static _API_UPDATE_USER_DATA = "/Account/ReviseUserData";

  static _API_GET_LOGIN_FAILED_COUNT = "/Account/RetrieveLoginFailCount";
  static _API_MANAGE_MEMBER_LOGIN_FAIL_COUNT =
    "/Account/ManageMemberLoginFailCount";

  static _API_GET_MEMBER_DETAILS_BY_ID = "/Member/RetrieveMemberDetailsById";
  static _API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT =
    "/Member/RetrieveMemberDownlineGameByMasterProduct";
  static _API_UPDATE_DOWNLINE_MEMBER_GAME = "/Game/ReviseDownlineMemberGame";
  static _API_GET_MEMBER_DETAILS_DASHBOARD =
    "/Member/RetrieveMemberDetailsDashboard";
  static _API_GET_NOTIFICATION_LIST = "/Member/RetrieveNotificationList";
  static _API_GET_VIDEO = "/Member/RetrieveVideo";
  static _API_MEMBER_RESET_PASSWORD = "/Member/ResetPassword";
  static _API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN =
    "/Member/ResetPasswordBeforeLogin";
  static _API_GET_ANNOUNCEMENT = "/Member/RetrieveAnnouncement";
  static _API_MARK_ANNOUNCEMENT_AS_READ = "/Member/MarkAnnouncementRead";
  static _API_GET_USER_MEMBERS = "/Member/RetrieveUserMember";
  static _API_GET_USER_MEMBER_LIST = "/Member/RetrieveUserMemberList";
  static _API_GET_USER_MEMBER_LIST_LATEST = "/Member/RetrieveUserMemberListLatest";
  static _API_GET_MEMBER_PRODUCT_RATES = "/Member/RetrieveMemberProductRates";
  static _API_GET_MEMBER_UPLINE_PRODUCT_RATES = "/Member/RetrieveUplineProductRates";
  static _API_GET_MEMBER_REFERRAL_CODES = "/Member/RetrieveMemberReferralCodes";
  static _API_GET_MEMBER_REFERRAL_GROUPS_BY_CODE =
    "/Member/RetrieveMemberReferralGroupsByCode";
  static _API_GET_MEMBER_REFERRAL_GROUPS_LIST =
    "/Member/RetrieveMemberReferralGroupsList";
  static _API_DEL_REFERRAL = "/Member/DelMemberReferralCode";
  static _API_GENERATE_MEMBER_REFERRAL_CODE = "/Member/GenerateReferralCode";
  static _API_GET_UPLINE_BY_REFERRAL_CODE = "/Member/RetrieveUplineByReferralCode";
  static _API_GET_DOWNLINE_BY_REFERRAL_ID = "/Member/RetrieveDownlineByReferralId";
  static _API_CHECK_USERNAME = "/Member/VerifyUsername";
  static _API_GET_MEMBER_SUMMARY_KPI = "/Member/RetrieveMemberSummaryKpi";
  static _API_GET_MEMBER_DETAIL_KPI = "/Member/RetrieveMemberKpiDetail";
  static _API_UPDATE_MEMBER_NICKNAME = "/Member/ReviseMemberNickname";
  static _API_UPDATE_MEMBER_DOWNLINE = "/Member/ReviseMemberDownline";
  static _API_CHECK_MEMBER_PIN_NUMBER = "/Member/VerifyMemberPinNumber";
  static _API_SET_MEMBER_PIN_NUMBER = "/Member/SetMemberPinNumber";
  static _API_RESET_MEMBER_PIN_NUMBER = "/Member/ResetMemberPinNumber";
  static _API_CHECK_SHAREHOLDER_PIN_NUMBER =
    "/Member/VerifyShareholderPinNumber";
  static _API_SET_SHAREHOLDER_PIN_NUMBER = "/Member/SetShareholderPinNumber";
  static _API_RESET_SHAREHOLDER_PIN_NUMBER =
    "/Member/ResetShareholderPinNumber";
  static _API_CHECK_IF_PHONE_VERIFIED = "/Member/VerifyIfPhoneVerified";
  static _API_GET_COMPANY_SKIN_SETTINGS = "/Member/RetrieveCompanySkinSettings";

  static _API_GET_MEMBER_PRODUCT_DETAILS = "/Member/RetrieveMemberProductDetails";

  static _API_CREATE_OR_UPDATE_DOWNLINE_GROUP =
    "/Member/InitiateOrReviseDownlineGroup";
  static _API_ADD_OR_REMOVE_MEMBER_INTO_GROUP =
    "/Member/AddOrRemoveMemberIntoGroup";
  static _API_GET_DOWNLINE_GROUPS = "/Member/RetrieveDownlineGroups";
  static _API_GET_DOWNLINE_GROUP_DETAIL_BY_ID =
    "/Member/RetrieveDownlineGroupDetailById";
  static _API_DELETE_DOWNLINE_GROUP = "/Member/DeleteDownlineGroup";
  static _API_GET_UNGROUP_MEMBER_LIST = "/Member/RetrieveUngroupMemberList";
  
  static _API_GET_UNCLAIM_COMMISSION_AMT = "/Member/GetUnclaimCommissionAmt";
  static _API_GET_UNCLAIM_REFERRAL_BONUS = "/Member/GetUnclaimReferralBonus";
  

  static _API_CHECK_HIDE_SUMMARY = "/Member/VerifyHideSummary";

  static _API_GET_USER_FULLNAME = "/Member/RetrieveUserFullName";

  static _API_GET_REPORT_OVERVIEW_SELECT_DATE =
    "/Member/RetrieveReportOverviewSelectDate";
  static _API_GET_MEMBER_WALLET_STATEMENT =
    "/Transaction/RetrieveMemberWalletStatement";
  static _API_GET_MEMBER_REWARD_HISTORY = "/Transaction/RetrieveMemberRewardHistory";
  static _API_GET_TRANSACTION_BY_MEMBER_ID =
    "/Transaction/RetrieveTransactionByMemberId";
  static _API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL =
    "/Transaction/RetrieveTransactionByMemberDepositAndWithdrawal";
  static _API_GET_ROLLOVER_RECORD = "/Transaction/RetrieveRolloverRecord";
  static _API_CREATE_TRANSACTION = "/Transaction/InitiateTransaction";
  static _API_GET_BONUS_HISTORY = "/Transaction/RetrieveBonusHistory";
  static _API_GET_INTERNAL_TRANSFER = "/Transaction/RetrieveInternalTransferHistory";
  static _API_GET_COMMISSION_HISTORY = "/Transaction/RetrieveCommissionHistory";
  static _API_GET_PENDING_TRANSACTION = "/Transaction/VerifyPendingTransaction";

  static _API_GET_CLAIM_HISTORY = "/Transaction/RetrieveClaimHistory";
  static _API_GET_VADERPAY_BANK_OPTION = "/Transaction/RetrieveVaderPayBankOption";

  static _API_GET_GAME_RECORD_BY_MEMBER = "/Game/RetrieveProductGameRecordByMember";
  static _API_GET_OVERALL_GAME_RECORD_BY_MEMBER =
    "/Game/RetrieveProductOverallGameRecordByMember";

  static _API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER =
    "/Bank/RetrieveMemberBankAccountByMember";
  static _API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT =
    "/Bank/InitiateOrReviseMemberBankAccount";
  static _API_DELETE_MEMBER_BANK_ACCOUNT = "/Bank/DeleteMemberBankAccount";
  static _API_GET_USER_BANK = "/Bank/RetrieveUserBank";
  static _API_GET_BANK_ACCOUNT_BY_BANK_ID = "/Bank/RetrieveBankAccountByBankId";
  static _API_GET_BANK_ACCOUNT = "/Bank/RetrieveBankAccount";
  static _API_GET_BANK_ACCOUNT_2 = "/Bank/RetrieveBankAccount2";

  static _API_CREATE_WALLET_TRANSFER_TRANSACTION =
    "/Transaction/InitiateWalletTransferTransaction";

  static _API_GET_NON_SEAMLESS_PRODUCT_BALANCE =
    "/Game/RetrieveNonSeamlessProductBalance";
  static _API_GET_ASSIGNED_MEMBER_PRODUCT = "/Game/RetrieveAssignedMemberProduct";
  static _API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST =
    "/Game/RetrieveAssignedMemberProductShowList";
  static _API_UPDATE_MEMBER_PRODUCT_RATE = "/Game/ReviseMemberProductRate";
  static _API_UPDATE_DOWNLINE_PRODUCT_RATE = "/Game/ReviseDownlineProductRate";
  static _API_UPDATE_MEMBER_PRODUCT_ACTIVENESS =
    "/Game/ReviseMemberProductActiveness";
  static _API_GET_POOL_RESULT = "/Game/RetrievePoolResult";
  static _API_GET_PRODUCT_WITH_MAX_RATE = "/Game/RetrieveGroupedProductWithMaxRates";
  static _API_GET_CATEGORY_GROUP_GAME = "/Game/RetrieveCategoryGroupedGame";
  static _API_GET_CATEGORY_GROUP_GAME_DASHBOARD =
    "/Game/RetrieveCategoryGroupedGameDashboard";

  static _API_GET_MASTER_PRODUCT_GROUP_GAME =
    "/Game/RetrieveMasterProductGroupedGame";
  static _API_GET_MASTER_PRODUCT_GROUP_GAME_BY_GAME_ID =
    "/Game/RetrieveMasterProductGroupedGameByGameId";
  static _API_GET_RECOMMENDED_MASTER_PRODUCT_GROUP_GAME =
    "/Game/RetrieveRecommendedMasterProductGroupedGame";
  static _API_GET_MASTER_PRODUCT_BY_ID = "/Game/RetrieveMasterProductById";
  static _API_GET_IS_NEW_MASTER_PRODUCT_GROUP_GAME =
    "/Game/RetrieveIsNewMasterProductGroupedGame";

  static _API_CHECK_IF_NON_SEAMLESS = "/Game/VerifyIfNonSeamless";
  static _API_CHANGE_PRODUCT_PASSWORD = "/Game/ChangeProductPassword";
  static _API_GET_GAME_IS_NEW_WINDOW = "/Game/RetrieveGameIsNewWindow";

  static _API_START_GAME = "/Game/StartGame";
  static _API_GET_BET_DETAILS = "/Game/RetrieveProductGameRecordById";
  static _API_GET_COMMUNICATION_CHANNELS = "/Member/RetrieveCommunicationChannel";

  static _API_SEND_VERIFICATION_CODE = "/Member/SendVerificationCode";
  static _API_SEND_PIN_CODE = "/Member/SendPinCode";
  static _API_VERIFY_PHONE_NUMBER = "/Member/ValidatePhoneNumber";
  static _API_CHECK_DISPLAY_BANK_DETAIL = "/Bank/VerifyBankDetails";

  static _API_GET_USER_BANNER = "/Member/RetrieveUserBanner";
  static _API_MANAGE_BANNER_NOTIFICATION = "/Member/ManageBannerNotification";
  static _API_GET_USER_REWARD_BANNER = "/Member/RetrieveUserRewardBanner";
  static _API_GET_PRODUCT_CATEGORY = "/Member/RetrieveProductCategory";
  static _API_GET_PRODUCT_CATEGORY_WITH_MASTER_PRODUCT =
    "/Member/RetrieveProductCategoryWithMasterProduct";
  static _API_GET_MASTER_PRODUCT_CATEGORY = "/Member/RetrieveMasterProductCategory";
  static _API_GET_USER_VIP_LEVEL = "/Member/RetrieveUserVipLevel";

  static _API_GET_MEMBER_SHAREHOLDER_DETAIL =
    "/Member/RetrieveMemberShareholderDetail";
  static _API_GET_MEMBER_SHAREHOLDER_UNCLAIM =
    "/Member/RetrieveMemberShareholderUnclaim";
  static _API_UPDATE_SHAREHOLDER = "/Member/ReviseShareholder";
  static _API_CLAIM_SHAREHOLDER = "/Member/ClaimShareholder";
  static _API_CLAIM_COMMISSION = "/Member/ClaimCommission";
  static _API_CLAIM_BONUS_REFERRAL = "/Member/ClaimReferralBonus";
  static _API_CLAIM_REWARD = "/Member/ClaimReward";

  static _API_UPDATE_MEMBER_THEME = "/Member/ReviseMemberTheme";

  //static _API_CHECK_PHONE_NUMBER = "/Member/VerifyPhoneNumber";

  static _API_GET_COMPANY_TNG_SYSTEM_SETTINGS =
    "/Member/RetrieveCompanyTnGSystemSettings";
  static _API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET =
    "/Game/RetrieveFlagTransferBackMainWallet";

  static _API_SPG_CHECK = "/Transaction/CheckSPG";

  static _API_SEND_TAC_CODE = "/Member/SendTACCode";
  static _API_VERIFY_TAC_CODE = "/Member/ValidateTACCode";

  static _API_GET_USER_SYSTEM_SETTINGS = "/Member/RetrieveUserSystemSettings";

  static _API_ADD_OPERATION_LOG = "/Account/AddOperationLog";

  static _API_GET_CATEGORY_REWARD_RATES = "/Member/RetrieveCategoryRewardRates";

  static _API_UPDATE_MEMBER_PHONE_NUMBER = "/Member/ReviseMemberPhoneNumber";
  static _API_UPDATE_MEMBER_LANGUAGE = "/Member/ReviseMemberLanguage";
  static _API_UPDATE_MEMBER_USERFULLNAME = "/Account/ReviseMemberUserFullname";

  static _API_CHECK_MEMBER_ROLLOVER = "/Member/VerifyMemberRollover";

  static _API_GET_COMPANY_CONTENT_LANGUAGE =
    "/Account/RetrieveCompanyContentLanguage";

  static _API_GET_WALLET_TRANSFER = "/Account/RetrieveWalletTransfer";
  static _API_CHECK_IF_REFERRAL_CODE_EXIST = "/Member/VerifyIfReferralCodeExist";
  static _API_GET_MEMBER_DETAILS_BY_ID_AND_DATE =
    "/Member/RetrieveMemberDetailsByIdAndDate";
  static _API_GET_COMMISSION_AMT = "/Member/RetrieveCommissionAmt";
  static _API_GET_NUMBER_OF_GEN = "/Member/RetrieveNumberOfGen";

  static _API_GET_DEPOSIT_PROMOTION_BY_MASTER_PRODUCT_ID =
    "/Member/RetrieveDepositPromotionByMasterProductId";

  static _API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS =
    "/Member/RetrieveDepositPromotionRolloverProgress";

  static _API_GET_DEPOSIT_PROMOTION = "/Member/RetrieveDepositPromotion";
  static _API_GET_DEPOSIT_PROMOTION_BY_ID = "/Member/RetrieveDepositPromotionById";
  static _API_GET_DEPOSIT_PROMOTION_INFO_BY_ID =
    "/Member/RetrieveDepositPromotionInfoById";

  static _API_GET_MEMBER_REFERRAL_LIST = "/Member/RetrieveUserMemberReferralList";

  static _API_GET_NON_SEAMLESS_PRODUCT = "/Game/RetrieveNonSeamlessProduct";
  static _API_GET_MARQUEE_TAG_CONTENT = "/Member/RetrieveMarqueeTagContent";
  static _API_GET_MARQUEE_TAG_CONTENT_BY_PLATFORM =
    "/Member/RetrieveMarqueeTagContentByPlatform";

  static _API_GET_RESTORE = "/Bank/RestoreFunction"; // TODO
  static _API_GET_ALL_VIP_CRITERIA = "/Member/RetrieveAllVipCriteriaByCompany";
  static _API_GET_VIP_BENEFIT_CONTENT = "/Member/RetrieveVipBenefitContent";
  static _API_GET_ALL_VIP_COMMISSION = "/Member/RetrieveAllVipCommissionByCompany";

  static _API_GET_MEMBER_WALLET_BALANCE = "/Account/RetrieveMemberWalletBalance";
  static _API_GET_LATEST_ROLLOVER_RECORD =
    "/Transaction/RetrieveLatestRolloverRecord";
  static _API_GET_MASTER_PRODUCT_GROUP_GAME_LIST =
    "/Game/RetrieveMasterProductGroupedGameList";
  static _API_REDEEM_VOUCHER = "/Transaction/RedeemVoucher";
  static _API_CHECK_MEMBER_BANK_ACCOUNT_BY_FULLNAME =
    "/Bank/VerifyMemberBankAccountByFullname";
  static _API_GET_APP_LOGIN_CRED = "/Member/RetrieveAppLoginCred";
  static _API_GET_WEEKLY_RESCUE = "/Member/RetrieveWeeklyRescue";
  static _API_CHECK_ANY_UNREAD_NOTIFICATION =
    "/Member/VerifyAnyUnreadNotification";
  static _API_PRODUCT_COMM_RATE = "/Game/RetrieveProductCommRate";
  static _API_UPDATE_REFERRAL_CODE = "/Member/ReviseReferralCode";
  static _API_CREATE_REFERRAL_CODE = "/Member/InitiateReferralCode";
  static _API_GET_TYPE_BANK_ACCOUNT = "/Bank/RetrieveTypeBankAccount";
  static _API_CREATE_TOPKASH_TRANSACTION =
    "/Transaction/InitiateTopkashTransaction";

  static _API_CREATE_TRANSACTION_TOPKASH =
    "/Transaction/InitiateTransactionTopkash";
  static _API_GET_MEMBER_DETAIL_KPI2 = "/Member/RetrieveMemberKpiDetail2";

  // Daily Check In
  static _API_GET_DAILY_CHECK_IN_RECORD = "/Member/RetrieveDailyCheckInRecord";
  static _API_CREATE_MEMBER_DAILY_CHECK_IN = "/Member/InitiateMemberDailyCheckIn";
  static _API_GET_ALL_DC_ITEM = "/Member/RetrieveAllDCItemForMember";
  static _API_REDEEM_DC_ITEM = "/Member/RedeemDCItem";
  static _API_GET_DC_REDEMPTION_HISTORY = "/Member/RetrieveDCRedemptionHistory";
  static _API_CREATE_VOUCHER_TRANSACTION =
    "/Transaction/InitiateDCVoucherTransaction";
  static _API_GET_UNCLAIM_DC_POINTS = "/Member/RetrieveUnclaimDCPoints";
  static _API_CLAIM_DC_POINTS = "/Member/ClaimDCPoints";
  static _API_GET_DC_POINTS_HISTORY = "/Transaction/RetrieveDCPointsHistory";
  static _API_GET_MEMBER_DC_POINTS_BY_ID = "/Member/RetrieveMemberDCPointsById";
  static _API_CHECK_MEMBER_DC = "/Member/VerifyMemberDailyCheckIn";
  static _API_GET_DCITEM_CONTENT = "/Member/RetrieveDCItemContent";

  static _API_GENERATE_OTP = "/Member/GenerateOTP";
  static _API_CHECK_MEMBER_EMAIL = "/Member/CheckMemberEmail";

  static _API_CHECK_EMAIL = "/Member/VerifyEmail";
  static _API_UPDATE_MEMBER_EMAIL_AND_BIRTH_DATE =
    "/Account/ReviseMemberEmailAndBirthDate";

  static _API_GET_VIP_COMMISSION_HISTORY =
    "/Transaction/RetrieveVipCommissionHistory";
  static _API_GET_EXTRA_HTML_STYLE = "/Member/RetrieveExtraHtmlStyle";
  static _API_GET_CRYPTO_WALLETS = "/Bank/RetrieveCryptoWallets";
  static _API_GET_RECENT_GAMES = "/Game/RetrieveRecentgame";

  //Get Separated Promotion
  static _API_GET_DEPOSIT_PROMOTION_CATEGORY =
    "/Member/RetrieveDepositPromotionCategory";
  static _API_GET_DEPOSIT_PROMOTION_CATEGORY_BY_ID =
    "/Member/RetrieveDepositPromotionCategoryById";
  static _API_GET_LATEST_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL =
    "/Transaction/RetrieveLatestTransactionByMemberDepositAndWithdrawal";
  static _API_GET_LATEST_GAME_RECORD_BY_MEMBER =
    "/Game/RetrieveLatestProductGameRecordByMember";
  static _API_GET_SUMMARY_GAME_RECORD = "/Game/RetrieveSummaryProductGameRecord";
  static _API_GET_LATEST_GAME_RECORD = "/Game/RetrieveLatestProductGameRecord";
  static _API_GET_LATEST_BETS = "/Game/RetrieveLatestBets";
  static _API_START = "/Game/Start";
  static _API_GET_MEMBER_ACCOUNT_BALANCE = "/Account/RetrieveMemberAccountBalance";

  static _API_GET_PAYMENT_GATEWAY_INFO_BY_ID = "/Account/RetrievePaymentGatewayInfoById";
  static _API_GET_MEMBER_UPGRADE_BONUS_RECORD = "/Account/RetrieveMemberUpgradeBonusRecord";
  static _API_REDEEM_MEMBER_VIP_BONUS = "/Transaction/RedeemUpdateVipBonus";
  static _API_GET_BONUS_DEPOSIT = "/Transaction/GetBonusDeposit";

  static _API_CLAIM_PROMOTION_FS = "/Transaction/ClaimPromotion";
  static _API_RESTORE_ALL_WALLET = "/Transaction/RestoreAllWallet";

  static _API_GET_RELATED_DEPOSIT_PROMOTION_BY_PRODUCT_ID =
    "/Member/RetrieveRelatedDepositPromotionByProductId";

  static _API_RESTORE_MAIN_WALLET = "/Transaction/RestoreMainWallet";
  static _API_UPDATE_TRANSACTION_VALID_FOR_PROMO = "/Transaction/UpdateTransactionValidForPromo";

  static _API_UPDATE_TEMP_WALLET = "/Transaction/UpdateAllTempWallet";
  static _API_GET_CHAT_SPHERE_NUMBERS = "/Account/RetrieveChatSpherePhoneNumber";
  
  static _API_SEND_CHAT = "/api/chat/user/send";
  static _API_SEND_FILE = "/api/chat/user/file";
  static _API_SEND_VOICE= "/api/chat/user/voice";
  static _API_MARK_LIVECHAT_AS_READ = "/Member/MarkLivechatRead";

  static _API_GET_CHAT_LIST = "/Member/GetChat";

  static _API_GET_TOP_DEPOSIT_WITHDRAWAL= "/Member/GetLatestDepositWithdrawal";

  static _API_GET_RECENT_PAYOUT = "/Account/GetRecentPayout";

  static _API_GET_BIG_WIN = "/Account/GetBigWin";
}

/// <summary>
/// Author :
/// Url to navigate to pages
/// </summary>
export class WebUrl {
  static _URL_MAIN = "/";
  static _URL_LOGIN = "/login";
  static _URL_WELCOME = "/welcome";
  static _URL_FORGOT = "/forgot";
  static _URL_MLMREGISTER = "/register";
  static _URL_MLMCREATEANACCOUNT = "/create-an-account";
  static _URL_MLMCREATEANACCOUNTPUBLIC = "/create-new-account";
  static _URL_REGISTER = "/referral";
  
  static _URL_REFERRAL_GROUPS = "/referral-groups"; ///currently using
  static _URL_REFERRAL_GROUP_DETAIL = "/referral-group-detail"; ///currently using
  static _URL_KEY_IN_REFERRAL = "/referralKey";

  static _URL_UNDER_MAINTENANCE = "/under-maintenance";
  static _URL_CAMO_REGISTER = "/register-page";
  static _URL_DASHBOARD = "/dashboard";
  static _URL_DASHBOARD2 = "/dashboard2";
  static _URL_DASHBOARD_V2 = "/dashboardv2";
  static _URL_CAMO_HOMEPAGE = "/home-page";
  static _URL_QR_CODE = "/qr-code";
  //static _URL_RELOAD = "/reload";
  static _URL_WITHDRAWAL = "/withdrawal";
  static _URL_WITHDRAWAL_RECORD = "/withdrawal-record";
  static _URL_DEPOSIT_RECORD = "/deposit-record";
  static _URL_CASH_RELOAD = "/cash-reload";
  static _URL_CASH_BALANCE = "/cash-balance";
  static _URL_CASH_BALANCE_HISTORY = "/cash-balance-history";
  static _URL_DOWNLINE = "/downline"; ///currently using
  static _URL_DOWNLINE_DETAILS = "/downline-details"; ///currently using
  static _URL_MY_DOWNLINES = "/downlines"; ///currently using
  static _URL_FORGET_PIN = "/forget-pin";
  static _URL_POINT_TRANSFER = "/point-transfer";
  static _URL_POINT_BALANCE = "/point-balance";
  static _URL_RECEIPTS = "/receipts";
  static _URL_SETTINGS = "/settings"; ///currently using
  static _URL_VIDEO_TUTORIAL = "/video-tutorial";
  static _URL_NEWS = "/news"; ///currently using
  static _URL_NEWS_DETAILS = "/news-details"; ///currently using
  static _URL_PACKAGES = "/packages";
  static _URL_TICKETS = "/tickets";
  static _URL_KPI = "/kpi"; ///currently using
  static _URL_GAME_LOBBY = "/game-lobby"; ///currently using
  static _URL_DEPOSIT = "/deposit"; ///currently using
  static _URL_TRANSACTION = "/transaction"; ///currently using
  static _URL_TRANSFER_SUCCESS = "/transfer-successful"; ///currently using
  static _URL_RELOAD_TRANSACTION = "/reload";
  static _URL_WALLET_HISTORY = "/report/wallet-history"; ///currently using
  static _URL_WALLET_HISTORY_MOBILE = "/report/wallet-historyM";
  static _URL_TRANSACTION_HISTORY = "/report/transaction-history"; ///currently using
  static _URL_BONUS_HISTORY = "/report/bonus-history";
  static _URL_INTERAL_TRASNFER_HISTORY = "/report/internal-transfer-history";
  static _URL_WALLET_TRANSFER_HISTORY = "/report/wallet-transfer-history";
  static _URL_COMMISSION_HISTORY = "/report/commission-history"; ///currently using
  static _URL_ROLLOVER_STATEMENT = "/report/rollover-statement"; ///currently using
  static _URL_GAME_HISTORY = "/report/game-history"; ///currently using
  static _URL_MEMBERS_TRANSFER = "/report/members-transfer"; ///currently using
  static _URL_GAME_WALLET_TRANSFER = "/report/game-wallet-transfer"; ///currently using
  static _URL_REPORTS_OVERVIEW = "/report/overview"; ///currently using
  static _URL_GAME_DETAILS = "/report/game-details";
  static _URL_MANAGE_REFERRAL_CODES = "/referral-codes";
  static _URL_REFERRAL_CODE_DETAIL = "/referral-code-detail";
  static _URL_REPORTS = "/report";
  static _URL_GAME_WALLET = "/game-wallet";
  static _URL_MAINTENANCE = "/maintenance";
  static _URL_CONTACT_US = "/contact-us";
  static _URL_LIVE_CHAT = "/live-chat";
  static _URL_LIVECHAT = "/livechat";
  static _URL_SHAREHOLDER = "/shareholder";
  static _URL_SHAREHOLDER_DETAIL = "/shareholder-details";
  static _URL_EDIT_SHAREHOLDER = "/edit-shareholder";
  static _URL_DOWNLINE_GROUP_DETAIL = "/downline-group-detail";
  static _URL_UNCLAIM_COMMISSION_HISTORY = "/unclaim-commission-history";
  static _URL_TNC = "/tnc";
  static _URL_REWARDS_TNC = "/rewards-tnc";
  static _URL_CLAIM_RECORD = "/claim-history";
  static _URL_SUMMARY_DOWNLINE_SALES = "/shareholder/downline";
  static _URL_REWARDS = "/rewards";
  static _URL_REWARDS_HISTORY = "/rewards/history";
  static _URL_REWARDS_DETAIL = "/rewards/detail";
  static _URL_GAME_PAGE = "/game-page";
  static _URL_GAME_RELOAD = "/game-reload";
  static _URL_ACTIVITY_MAIN = "/activity";
  static _URL_WALLET_STATEMENT = "/wallet-statement";
  static _URL_WITHDRAWAL_METHODS = "/withdraw-methods"; ///currently using
  static _URL_REFERRAL_BONUS = "/referral-bonus"; ///currently using
  static _URL_WITHDRWAL_BY_BANK_TRANSFER = "/withdraw-by-bank-transfer"; ///currently using
  static _URL_SCAN_QR = "/scan-qr";
  static _URL_CREATE_ACCOUNT = "/scan-qr/create-account";
  static _URL_VERIFY_CREATE_ACCOUNT = "/scan-qr/verify";
  static _URL_CREATE_PASSWORD = "/scan-qr/create-password";
  static _URL_CREATE_ACCOUNT_SUCCESS = "/success";
  static _URL_CREATE_ACCOUNT_BY_REFERRAL_CODE = "/key-in-referral";
  static _URL_WITHDRAWAL_IN_PROGRESS = "/withdrawal-in-progress";
  static _URL_MAIN_WALLET = "/wallet"; ///currently using
  static _URL_RELOAD_BANK = "/deposit/reload-bank"; ///currently using
  static _URL_GIFTPACE = "/deposit/giftpace"; ///currently using
  static _URL_RELOAD_TOUCH_N_GO = "/transaction/reload-touchngo"; ///currently using
  static _URL_UPLOAD_RELOAD_RECEIPT = "/deposit/upload-reload-receipt"; ///currently using
  static _URL_RELOAD_DONE = "/deposit/reload-done"; ///currently using
  static _URL_COMMISSION = "/commission"; ///currently using
  static _URL_TRANSFER = "/transfer"; ///currently using
  static _URL_VIP_BENEFITS = "/vip-benefits";
  static _URL_PROMOTION = "/promotion";
  static _URL_PROMOTION_INFO = "/promotion-information";
  static _URL_HOME = "/";
  static _URL_COMMISSIONS_INFO = "/commissions-info";
  static _URL_TOPKASH = "/deposit/topkash";
  static _URL_WITHDRAWAL_BY_TOPKASH = "/withdraw-by-topkash";
  static _URL_VIP_COMMISSION_HISTORY = "/vip-commission-history";
  static _URL_HUIONE_DEPOSIT = "/huione-deposit";
  static _URL_WITHDRAWAL_BY_HUIONE = "/withdraw-by-huione";
  static _URL_CRYPTO_WALLETS = "/crypto-wallets";
  static _URL_CRYPTO_DEPOSIT = "/crypto-deposit";
  // Daily Check In
  static _URL_DAILY_CHECK_IN = "/daily-check-in"; ///currently using
  static _URL_DC_REDEMPTION = "/daily-check-in-redemption"; ///currently using
  static _URL_DC_REDEMPTION_HISTORY = "/daily-check-in-redemption-history"; ///currently using
  static _URL_UNCLAIM_DC_POINTS = "/unclaim-daily-check-in-points";
  static _URL_DC_POINTS_HISTORY = "/daily-check-in-points-history"; ///currently using
  static _URL_WING_WEILUY_DEPOSIT = "/wing-weiluy-deposit"; ///currently using
  static _URL_UPLOAD_WING_WEILUY_RECEIPT = "/deposit/upload-wing-weiluy-receipt"; ///currently using
  static _URL_WITHDRAW_BY_WING_WEILUY = "/withdraw-by-wing-weiluy"; ///currently using

  static _URL_WITHDRAWAL_BY_BCPAY = "/withdraw-by-bcpay";
  static _URL_FS_PROMOTION = "/fs-promotion";

  static _URL_RECENT_BIG_WIN = "/recent-big-win";
  
}

/// <summary>
/// Author :
/// </summary>
export class Status {
  static _ENABLED = 1;
  static _DISABLED = 0;
  static _DELETED = 2;
}

/// <summary>
/// Author :
/// </summary>
export class Language {
  static _ENGLISH = "en";
  static _KHMER = "kh";
  static _CHINESE = "zh";
  static _VIETNAMESE = "vi";
  static _INDON = "id";
  static _PORTUGUESE = "pt";
}

/// <summary>
/// Author :
/// </summary>
export class SessionKey {
  static _LANGUAGE = "language";
  static _LOGIN_GUID = "loginGuid";
  static _TEMP_GUID = "tempGuid";
}

/// <summary>
/// Author :
/// </summary>
export class Role {
  static _SUPER_ADMIN = 1;
  static _SHOP = 2;
  static _MANAGER = 3;
  static _MEMBER = 4;
  static _ADMIN_SUB_ACCOUNT = 0;
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionType {
  static _DEPOSIT = 1;
  static _WITHDRAWAL = 2;
  static _BONUS = 3;
  static _ADJUSTMENT = 4;
  static _REBATE = 5;
  static _TRANSFER = 6;
  static _REFERRALBONUS = 7;
  static _REFUND = 8;
  static _COMMISSION = 9;
  static _BIRTHDAY_REWARD = 10;
  static _DAILY_RESCUE = 13;
  static _CREDIT_REDEMPTION = 14;
  static _VIP_UPGRADE_BONUS = 15;

  static _REWARD = 999;
  static _DAILY_CHECK_IN = 999;
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionStatus {
  static _OPEN = 0;
  static _IN_REVIEW = 1;
  static _IN_PROGRESS = 2;
  static _APPROVED = 3;
  static _REJECTED = 4;
  static _AUTO_APPROVED = 5;
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
  static _REQUIRED_KEY = "required";
  static _MAX_LENGTH_KEY = "maxLength";
  static _MIN_LENGTH_KEY = "minLength";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
  static _DEFAULT = "default"; // (No icon for default)
  static _INFO = "info"; // dont use this
  static _SUCCESS = "success"; // work properly
  static _WARNING = "warning"; // work properly
  static _DANGER = "danger"; // work properly
  static _ERROR = "error"; // work properly
  static _INPUT = "input"; // work properly
  static _CUSTOM = "custom"; // (No icon for default)
}

/// <summary>
/// Author: -
/// </summary>
export class FilterStatus {
  static _ACTIVE = 1;
  static _DELETED = 2;
  static _SUSPENDED = 0;
}

/// <summary>
/// Author: -
/// </summary>
export class LoginType {
  static _LOGIN_TYPE_SHOP = 2;
  static _LOGIN_TYPE_ADMIN = 1;
}

/// <summary>
/// Author: -
/// this is match db value, do not change
/// </summary>
export class TopUpStatus {
  static _PENDING = 0;
  static _SUCCESS = 1;
  static _FAILED = 2;
}

/// <summary>
/// Author: -
/// </summary>
export class CommonStatus {
  static _ALL_STATUS = -1;
  static _PENDING_STATUS = 0;
  static _APPROVE_STATUS = 1;
  static _REJECT_STATUS = 2;
  static _INACTIVE_STATUS = 3;
}

export class TransferType {
  static _DEFAULT = "Default";
  static _CONVERT_POINTS = "Convert Points";
  static _BANK = "Bank";
  static _DOWNLINE = "Downline";
  static _UPLINE = "Upline";
}

/// <summary>
/// Author : -
/// </summary>
export class BankingChannel {
  static _CHANNEL_OFFLINE_BANKING = 1;
  static _CHANNEL_PAYTRUST = 2;
  static _CHANNEL_GPAY = 3;
}

/// <summary>
/// Author : -
/// </summary>
export class PanelType {
  static _MEMBERSITE = "MemberSite";
  static _MOBILESITE = "MobileSite";
  static _BACKOFFICE = "BackOffice";
  static _APPS = "Apps";
  static _SYSTEM = "Systems";
}

/// <summary>
/// Author : -
/// </summary>
export class BetStatus {
  static _PENDING_BET_STATUS = 0;
  static _SETTLED_BET_STATUS = 1;
  static _CANCELED_BET_STATUS = 2;
}

/// <summary>
/// Author : -
/// </summary>
export class LanguageKey {
  static _PRIMARY = "common";
}

/// <summary>
/// Author : -
/// </summary>
export class PaymentType {
  static _PREPAID = 2;
  static _POSTPAID = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class DisplayDevice {
  static _DESKTOP_MAIN = 0;
  static _DESKTOP_SUB = 1;
  static _MOBILE_POPOUT = 2;
  static _MOBILE_SLIDER = 3;
  static _DESKTOP_POPUP = 4;
}

/// <summary>
/// Author : -
/// </summary>
export const _TICKET_STATUS = [
  { value: null, label: "ALL" },
  { value: "0", label: "PENDING" },
  { value: "1", label: "SETTLED" },
  { value: "2", label: "CANCELLED" },
];

/// <summary>
/// Author : -
/// </summary>
export const _MAIN_WALLET_ID = 0;

export const LanguageOption = [
  {
    prefix: "ខ្មែរ",
    value: Language._KHMER,
    name: "ខ្មែរ",
    code: "kh",
    languageId: 2,
  },
  {
    prefix: "EN",
    value: Language._ENGLISH,
    name: "English",
    code: "en",
    languageId: 1,
  },
  {
    prefix: "中文",
    value: Language._CHINESE,
    name: "中文",
    code: "zh",
    languageId: 3,
  },
  {
    prefix: "VI",
    value: Language._VIETNAMESE,
    name: "Tiếng Việt",
    code: "vi",
    languageId: 4,
  },
  {
    prefix: "ID",
    value: Language._INDON,
    name: "Indonesia",
    code: "id",
    languageId: 5,
  },
];

export const LanguageSelectStyle = {
  control: (styles, state) => ({
    width: "60px",
    padding: ".5rem",
    borderRadius: "4px",
    background: "rgba(0, 0, 0, 0.3)",
  }),
  placeholder: (provided, state) => ({
    color: "#fff",
    margin: "0 auto",
  }),
  dropdownIndicator: (defaultStyles) => ({
    display: "none",
  }),
  indicatorsContainer: (defaultStyles) => ({
    display: "none",
  }),
  option: (provided, state) => ({
    fontSize: "12px",
    textAlign: "center",
    marginTop: "3px",
    marginBottom: "2px",
    padding: ".5rem 1rem",
    color: "#002e6c",
  }),
};

export const PhoneNumberPrefix = [
  {
    label: "+855",
    countryCode: "KH",
    value: "855",
  },
  {
    label: "+86",
    countryCode: "CN",
    value: "86",
  },
  {
    label: "+60",
    countryCode: "MY",
    value: "60",
  },
  {
    label: "+62",
    countryCode: "ID",
    value: "62",
  },
  {
    label: "+66",
    countryCode: "TH",
    value: "66",
  },
  {
    label: "+84",
    countryCode: "VI",
    value: "84",
  },
  {
	label: "+65",
	countryCode: "SG",
	value: "65",
  },
];

export const ThemeOption = [
  {
    value: "default",
    name: "Default",
    themeId: 1,
  },
  {
    value: "light",
    name: "Light",
    themeId: 2,
  },
  {
    value: "dark",
    name: "Dark",
    themeId: 3,
  },
];

export const DEFAULT_PAGE_SIZE = 10; 